
export default {
  methods: {
    logoClick() {
      this.$router.push("/");
      setTimeout(() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth"
        })
      );
    }
  }
};
